<template>
	<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;" class="font-famliy">
		<div class="zxyj-content-box" >
			<div class="banner-content">
				<div class="banner">
						<img style="height: 400px;" src="@/assets/images/banner/factory.png" alt="加载失败" />
				</div>
			</div>
		</div>
		<!-- 加工工厂 -->
		<div class="factory">
			<div class="xzyj-content" style="display: flex;flex-direction: column;">
				<div style="display: flex;align-items: center;margin-top: 30px;margin-left: 15px;">
					<el-breadcrumb separator="/">
					 <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					 <el-breadcrumb-item >{{route.meta.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="factory-list">
					<a class="factory-item" v-for="(item, i) in factory" style="display: block;" :key="i" :href="['/factoryDetail/index.html?id='+item.id+'&type=factory']">
						<div class="factory-item-detail" :class="[isIcon&&current===i?'back_color':'content'] "
						v-on:mouseenter="showIcon(i)" v-on:mouseleave="hideIcon()">
							<div style="height: 240px;">
								<img :src="item.image" alt="加载失败" />
							</div>
							<div :class="[isIcon && i==current ? 'white factory-item-title' : 'factory-item-title' ]" >
								<div style="display: flex;width: 100%;flex-direction: column; height:150px;iflex-grow:0;justify-content:flex-start;align-content:flex-start">
									<div style="font-size: 16px;display: flex; flex-grow:0;align-items: center;margin-top: 10px;justify-content: center;" class="case_name">
										<div style="-webkit-line-clamp:1;" class="font-overflow-lines">
											{{ item.title }}
										</div>
									</div>
									<div style="text-align: center;color:gray;">——</div>
									<div style="display: flex;margin-top: 6px;flex-grow:0;font-size: 12px;padding: 0 20px;" >
										<div style="-webkit-line-clamp:3; " class="font-overflow-lines">
											{{ item.subTitle }}
										</div>
									</div>
								</div>
								<!-- <div class="factory-tips" >
									<img style="height: 40px;width: 80px;" src="../../assets/images/detailicon_03.png" />
								</div> -->
							</div>
						</div>
					</a>
				</div>
				<div class="page" style="text-align: center;margin-top: 60px;">
					<el-pagination background layout="prev, pager, next" :page-size="params.pageSize" :total="total" @current-change="(val)=>{changePage(val)}">
					</el-pagination>
				</div>
			</div>
		</div>
		<div class="part3" style="display: none;">
			<div class="case">
				<!-- <div class="li" v-for="(item, i) in factory" :key="i" @click="detail(item.id)"> -->
				<a class="li" v-for="(item, i) in factory" style="display: block;" :key="i" :href="['/factoryDetail/index.html?id='+item.id+'&type=factory']">
					<div style="background-color: white;">
						<div class="content" style="width: 90%;">
							<div class="image">
								<img style="height: 250px;" :src="item.image" alt="" />
							</div>
							<div class="case_title" style="display: flex;flex-direction: column;height: 200px;">
								<div style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
									<div class="icon" style="display: flex; ">
										<img src="@/assets/images/icon.png" alt="" />
									</div>
									<div class="case_name font-overflow-line" style="-webkit-line-clamp: 1;">{{ item.title }}</div>
								</div>
								<div class="case_name font-overflow-line" style="margin-top: 10px;-webkit-line-clamp: 4;height: 70px;width: 250px;">{{ item.subTitle }}</div>
							</div>
						</div>
					</div>
				</a>
				<!-- </div> -->
			</div>

		</div>
		<div style="height: 100px"></div>
	</div>
</template>

<script>
	import http from "@/common/utils/Http";
	export default {
		metaInfo: {
			title: "智能爬架加工工厂-鑫昇腾",
			meta:[
				{name:"keywords", content: "爬架加工，智能爬架厂区，智能爬架组装"},
				{name:"description", content: "江苏鑫昇腾科技发展股份有限公司专注于建筑爬架的设计、制造和专业承包。"}
			]
		},
		data() {
			return {
				factory: [],
				total: 0,
				route: this.$route,
				params: {
					pageNum: 1,
					pageSize: 12
				},
				isIcon: false,
				current: 0,
			};
		},
		activated() {
			this.getFactory();
		},
		methods: {
			showIcon(index) {
				this.isIcon = true;
				this.current = index;
			},
			hideIcon() {
				this.isIcon = false;
				this.current = null;
			},
			getFactory() {
				let params = this.params;
				params.hot='1';
				http.api.get("/site/factory/list", {
					params
				}).then((res) => {
					this.factory = this.changeFactory(res.data.rows);
					this, this.total = res.data.total
				});
			},
			changePage(val) {
				this.params.pageNum = val
				this.getFactory()
			},
			changeFactory(arr) {
				let newArr = [];
				arr.forEach((row) => {
					let sub = {};
					sub.image = row.factoryUrl;
					sub.id = row.siteFactoryId;
					sub.title = row.factoryName;
					sub.subTitle = row.factoryIntroduction;
					newArr.push(sub);
				});
				return newArr;
			},
			// 打开详情页
			detail(id) {
				this.$router.push({
					path: "news_Detail",
					query: {
						type: "factory",
						id: id
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
.font-famliy  {
		div,a,span {font-family: MicrosoftYaHei;}
}
.banner-content {	margin-top: 80px;	position: relative;	height: 400px;width: 100%;}
.banner {	height: 100%;	width: 100% !important;	overflow: hidden;	text-align: center;	margin: 0 auto;	padding: 0;	z-index: 1;}
.factory {
	display: flex;
    justify-content: center;
	margin-bottom: 30px;

	.factory-list {
		display: flex;
		padding: 60px 30px 0 30px;
		justify-content: start;
		flex-wrap: wrap;

		.factory-item{
			display: block;
			height: 440px;
			width: 350px;
			margin-right: 30px;
			img{width: 350px;height: 240px;}

			.factory-item-detail{
				width: 350px;
				height: 390px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.factory-item-title{
				width: 100%;
				flex-direction: column;
				height: 340px;
				display: flex;
				flex-grow: 0;
				align-items: center;
				position: relative;
				background-color: #f6f6f6;
			}
			.white {
				background-color: #fff;
				box-shadow: 0px 8px 16px 0px rgba(28, 32, 44, 0.05);
			}
			.factory-tips {
				display: none;
				position: absolute; 
				bottom: 10px;
				height: 40px; 
				flex-grow:0;
			}
		}
	}
}
</style>
